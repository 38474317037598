import type DataObject from 'o365.modules.DataObject.ts';
import API from 'o365.modules.data.api.ts';
import Crc32 from 'o365.lib.crc32.js';
import ExifReader from 'exifreader';

namespace FileUpload {
    const defaultChunkSize = 4 * 1024 * 1024; // 4 MB

    type OnUploadProgress = (progress: IUploadProgress) => void;
    type OnBeforeUpload = (body: FormData) => Boolean;
    type OnAfterUpload = (result: any) => void;

    interface IFileUploadOptions {
     //   dataObject: DataObject;
        onUploadProgress?: OnUploadProgress;
        onBeforeUpload?: OnBeforeUpload;
        onAfterUpload?: OnAfterUpload;
        stopUploadOnError?: Boolean;
    }

    interface IUploadFileOptions extends IFileUploadOptions {
        file: File;
        viewName:string,
        data:Object,
        dataObject:DataObject
    }

    interface IBatchUploadFileOptions extends IFileUploadOptions {
        files: Array<File>;
    }

    interface IBulkUploadFileOptions extends IFileUploadOptions {
        files: Array<File>;
    }

    export interface IUploadProgress {
        filesToUpload: number;
        filesUploaded?: 0;
        filesUploadedWithError?: 0;
        currentFileUploadName?: string;
        currentFileUploadProgress?: 0;
        error?: string;
    }

    export async function upload(options: IUploadFileOptions): Promise<any> {
        const abortController = new AbortController();
        const requestBody = new FormData();

        const progress: IUploadProgress = {
            filesToUpload: 1
        }

        requestBody.append('File', options.file);

        /*const masterDetailsForm = _getDataObjectMasterDetails(options.dataObject);

        if (masterDetailsForm !== null) {
            for (const masterDetailsFormKey of Object.keys(masterDetailsForm)) {
                const masterDetailsFormValue = masterDetailsForm[masterDetailsFormKey];

                requestBody.append(masterDetailsFormKey, masterDetailsFormValue);
            }
        }

        if (options.onBeforeUpload) {
            const abort = options.onBeforeUpload(requestBody);

            if (abort) {
                return;
            }
        }*/

        if(options.data){
            Object.keys(options.data).forEach((key)=>{
                requestBody.append(key, options.data[key]);
            })
        }
        options.onUploadProgress?.call(progress);

        //var response: Response;

       // if (options.file.size <= defaultChunkSize) {
          return await _upload(options.viewName, requestBody, abortController.signal);
      //  } else {
         //   response = await _uploadFileAsChunks(options.viewName, requestBody, abortController.signal);
       // }

    }

    export async function uploadFile(options: IUploadFileOptions): Promise<any> {
        const abortController = new AbortController();
        const requestBody = new FormData();

        const progress: IUploadProgress = {
            filesToUpload: 1
        }

        requestBody.append('File', options.file);

        const masterDetailsForm = options.dataObject?_getDataObjectMasterDetails(options.dataObject):null;

        if (masterDetailsForm !== null) {
            for (const masterDetailsFormKey of Object.keys(masterDetailsForm)) {
                const masterDetailsFormValue = masterDetailsForm[masterDetailsFormKey];

                requestBody.append(masterDetailsFormKey, masterDetailsFormValue);
            }
        }

        if (options.onBeforeUpload) {
            const abort = options.onBeforeUpload(requestBody);

            if (abort) {
                return;
            }
        }
        options.onUploadProgress?.call(progress);

        var response: Response;

        if (options.file.size <= defaultChunkSize) {
            response = await _uploadFile(options.dataObject, requestBody, abortController.signal);
        } else {
            response = await _uploadFileAsChunks(options.dataObject, requestBody, abortController.signal);
        }

    }

    export function batchUploadFiles(options: IBatchUploadFileOptions) {
        throw new Error('Function not Implemented');
    }

    export function bulkUploadFiles(options: IBulkUploadFileOptions) {
        throw new Error('Function not Implemented');
    }

    async function _uploadFile(dataObject: DataObject, body: BodyInit, abortSignal?: AbortSignal): Promise<Response> {
        return await _sendPostRequest(dataObject, body, abortSignal);
    }

    async function _upload(pViewName:string, body: BodyInit, abortSignal?: AbortSignal): Promise<Response> {
        return await _sendRequest(pViewName, body, abortSignal);
    }

    async function _uploadFileAsChunks(dataObject: DataObject, body: BodyInit, abortSignal?: AbortSignal): Promise<Response> {
        // TODO: Create loop to split file into chunks and upload each chunk
        return await _sendPostRequest(dataObject, body, abortSignal);
    }

    async function _sendRequest(pViewName: string, body: BodyInit, abortSignal?: AbortSignal): Promise<Response> {
        const viewName = pViewName;
        const primKey = ''; // TODO: Add option to send in PrimKey

        const requestUrl = `/api/file/upload/${viewName}${primKey}`;


        return await API.request({
            requestInfo: requestUrl,
            body: body,
            method:"POST",
            abortSignal: abortSignal,
           
        });
    }


    async function _sendPostRequest(dataObject: DataObject, body: BodyInit, abortSignal?: AbortSignal): Promise<Response> {
        const viewName = dataObject.recordSource.viewName;
        const primKey = ''; // TODO: Add option to send in PrimKey

        const requestUrl = `/api/file/upload/${viewName}${primKey}`;

        return await API.request({
            requestInfo: requestUrl,
            body: body,
            abortSignal: abortSignal,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }

    function _getDataObjectMasterDetails(dataObject: DataObject) {
        const masterDetails = dataObject.masterDetails;
        const masterDetailsForm = masterDetails.getMasterDetailRowForInsert();

        return masterDetailsForm;
    }

    async function _calculateCrc32(file: File): Promise<any> {
        if (file.size < defaultChunkSize) {
            return {};
        }

        const fileReader = new FileReader();
        const crc32 = new Crc32();

        const chunker = () => {
            let i = 0;

            const checker = () => {

            };

            const readBlock = () => {

            };
        };

        chunker();


        // calculateCrc32(index)
        // return await this.calcCrc32(index, { files: this.#filesToUpload, blobMap: this.#filesBlobMap});
        
        // calcCrc32(index, data)
        // return await this.doCalculateCrc32({
        //     file: data.files[index],
        //     fileIndex: index,
        //     blobMap: data.blobMap.filter(function(x){return x.fileIndex === index})
        // })
        
        // doCalculateCrc32(data)
        // return new Promise(resolve => {
        //     if(data.file.size < 5*1024*1024*10){
        //         resolve({});
        //     }

        //     var reader = new FileReader();
        //     var crc32 = new Crc32(),
        //     crc32Update,

        //     chunker = function() {                            
        //         var file = data.file,
        //         i = 0,
        //         start = data.blobMap[i].start,
        //         stop = data.blobMap[i].end,
        //         fileSlicer;

        //         var checker = function(){
        //             i++;
        //             if(data.blobMap[i]){
        //                 start = data.blobMap[i].start;
        //                 stop = data.blobMap[i].end;
        //             }
        //             if(data.blobMap.length <= i) {
        //                 crc32Update.finalize();
        //                 resolve({ fileIndex: data.fileIndex, crc32CheckSum: crc32Update.crc });
        //             }
        //             readBlock(start, stop, file);
        //         };

        //         var readBlock = function(start, stop, file) {
        //             fileSlicer = file.slice(start, stop + 1);
        //             reader.onloadend = function(){
        //                 if(!crc32.finalized)
        //                 crc32Update = crc32.update(this.result);
        //                 checker();
        //             }
        //             reader.readAsArrayBuffer(fileSlicer);
        //         }; 

        //         readBlock(start, stop, file);
        //     };
        //     chunker();   
        // })
    }
}

export default FileUpload;
